import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import StatusIcon from 'src/components/StatusIcon';
import {
  IconStatusAndColor,
  IconStatusAndColorVariants
} from 'src/utils/constants/common';
import { Replay } from '@mui/icons-material';

export const AutomaticDebitCollectionStatus = {
  PENDING: 'PENDING',
  PROCESSED: 'PROCESSED',
  DEBITED: 'DEBITED',
  FAILED_TO_BE_PROCESSED: 'FAILED_TO_BE_PROCESSED',
  FAILED_TO_BE_DEBITED: 'FAILED_TO_BE_DEBITED',
  CANCELLED: 'CANCELLED'
};

export const AutomaticDebitCollectionOptions = {
  CANCEL: 'CANCEL',
  SET_NEW_DUE_DATE: 'SET_NEW_DUE_DATE',
  SEND_TO_REBILL: 'SEND_TO_REBILL'
};

export const getAutomaticDebitCollectionStatus = status => {
  switch (status) {
    case AutomaticDebitCollectionStatus.PROCESSED:
      return (
        <StatusIcon
          type={IconStatusAndColorVariants.LIGHT.OK.status}
          description={`ENUMS.AUTOMATIC_DEBIT_STATUS.${status}`}
          color={IconStatusAndColorVariants.LIGHT.OK.color}
        />
      );
    case AutomaticDebitCollectionStatus.DEBITED:
      return (
        <StatusIcon
          type={IconStatusAndColor.OK.status}
          description={`ENUMS.AUTOMATIC_DEBIT_STATUS.${status}`}
        />
      );

    case AutomaticDebitCollectionStatus.FAILED_TO_BE_PROCESSED:
    case AutomaticDebitCollectionStatus.FAILED_TO_BE_DEBITED:
      return (
        <StatusIcon
          type={IconStatusAndColor.ERROR.status}
          description={`ENUMS.AUTOMATIC_DEBIT_STATUS.${status}`}
        />
      );

    case AutomaticDebitCollectionStatus.CANCELLED:
      return (
        <StatusIcon
          type={IconStatusAndColorVariants.LIGHT.ERROR.status}
          description={`ENUMS.AUTOMATIC_DEBIT_STATUS.${status}`}
          color={IconStatusAndColorVariants.LIGHT.ERROR.color}
        />
      );

    case AutomaticDebitCollectionStatus.PENDING:
      return (
        <StatusIcon
          type={IconStatusAndColor.WARNING.status}
          description={`ENUMS.AUTOMATIC_DEBIT_STATUS.${status}`}
        />
      );

    default:
      return (
        <StatusIcon
          type={IconStatusAndColor.NONE.status}
          description="COMMON.NO_DATA"
        />
      );
  }
};

export const getAutomaticDebitCollectionMenuOptions = status => {
  const options = [
    {
      name: AutomaticDebitCollectionOptions.CANCEL,
      label: `ENUMS.AUTOMATIC_DEBIT_COLLECTION_ACTIONS.${AutomaticDebitCollectionOptions.CANCEL}`,
      icon: <CancelIcon />
    },
    {
      name: AutomaticDebitCollectionOptions.SET_NEW_DUE_DATE,
      label: `ENUMS.AUTOMATIC_DEBIT_COLLECTION_ACTIONS.${AutomaticDebitCollectionOptions.SET_NEW_DUE_DATE}`,
      icon: <CalendarMonthIcon />
    }
  ];

  if (status === AutomaticDebitCollectionStatus.FAILED_TO_BE_DEBITED) {
    options.push({
      name: AutomaticDebitCollectionOptions.SEND_TO_REBILL,
      label: `ENUMS.AUTOMATIC_DEBIT_COLLECTION_ACTIONS.${AutomaticDebitCollectionOptions.SEND_TO_REBILL}`,
      icon: <Replay />
    });
  }

  return options;
};

export default AutomaticDebitCollectionStatus;
